export const SESSION_ACTION_BUTTON_VIEWONMAP_FRAGMENT = `
  fragment sessionActionButtonViewonmapFragment on Session {
    uid
    typename: __typename
    _geozonesOfEdition {
      uid
      id
      schemaCode
      name
      exhibitHall {
        id
      }
    }
  }
`;
